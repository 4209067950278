import React, { FC } from 'react';
import { IConfig } from './IConfig';

interface Props {
  minutes: number;
  seconds: number;
  day?: number;
  calendar?: boolean;
  celsius?: number;
  theme: 'trapez' | 'bubble';
  config: IConfig;
}

export const Minutes: FC<Props> = ({ minutes, seconds, day, calendar, celsius, theme = 'trapez', config }) => {
  const getColor = (dot: number) => {
    switch (dot) {
      case 0:
        return '#00a0e3';
      case 1:
        return '#00a5e1';
      case 2:
        return '#00aadd';
      case 3:
        return '#00aed8';
      case 4:
        return '#00b2d2';
      case 5:
        return '#00b5cc';
      case 6:
        return '#00b8c5';
      case 7:
        return '#1ebbbe';
      case 8:
        return '#3abeb7';
      case 9:
        return '#4ec0b0';
      case 10:
        return '#52c1ac';
      case 11:
        return '#57c3a8';
      case 12:
        return '#5dc4a3';
      case 13:
        return '#63c59e';
      case 14:
        return '#6ac699';
      case 15:
        return '#71c794';
      case 16:
        return '#78c88f';
      case 17:
        return '#7fc88a';
      case 18:
        return '#87c985';
      case 19:
        return '#87c985';
      case 20:
        return '#8ece7d';
      case 21:
        return '#97d375';
      case 22:
        return '#a2d86b';
      case 23:
        return '#aedc61';
      case 24:
        return '#bce055';
      case 25:
        return '#cae348';
      case 26:
        return '#dbe639';
      case 27:
        return '#ece828';
      case 28:
        return '#fee90c';
      case 29:
        return '#ffdb00';
      case 30:
        return '#ffcc00';
      case 31:
        return '#ffbe00';
      case 32:
        return '#ffb004';
      case 33:
        return '#ffa110';
      case 34:
        return '#ff931a';
      case 35:
        return '#fc8522';
      case 36:
        return '#f77828';
      case 37:
        return '#f16a2e';
      case 38:
        return '#eb5131';
      case 39:
        return '#e43436';
      case 40:
        return '#1d489d';
      case 41:
        return '#1b499e';
      case 42:
        return '#194b9f';
      case 43:
        return '#174ca0';
      case 44:
        return '#144ea2';
      case 45:
        return '#114fa3';
      case 46:
        return '#0e51a4';
      case 47:
        return '#0b52a5';
      case 48:
        return '#0754a6';
      case 49:
        return '#0355a7';
      case 50:
        return '#005dae';
      case 51:
        return '#0065b6';
      case 52:
        return '#006ebd';
      case 53:
        return '#0076c4';
      case 54:
        return '#007eca';
      case 55:
        return '#0087d1';
      case 56:
        return '#008fd7';
      case 57:
        return '#0098dd';
      case 58:
        return '#00a0e3';
      case 59:
        return '#00a5e1';
    }
  };
  switch (theme) {
    case 'bubble':
      return (
        <g>
          {[...Array(60)].map((x, i) => {
            let path, color;
            if (i % 5 === 0) {
              path = 'M-11,0 l11,0l11,0l-3,64l-8,0l-8,0Z';
            } else {
              path = 'M-11,0 l11,0l11,0l-3,48l-8,0l-8,0Z';
            }

            if (celsius) {
              if (celsius > 0 && i <= celsius) {
                color = getColor(i);
              } else if (celsius < 0 && (i >= 60 + celsius || i == 0)) {
                color = getColor(i);
              } else if (celsius === 0) {
                color = getColor(i);
              } else {
                return <></>;
              }
            } else {
              return <></>;
            }

            return (
              <g
                key={'bubble-minutes-' + i}
                transform={`matrix(
          1,
          0,
          0,
          1,
          ${250 - 250 * Math.cos((((2 * Math.PI) / 60) * i + Math.PI / 2) % (2 * Math.PI))},
          ${250 - 250 * Math.sin((((2 * Math.PI) / 60) * i + Math.PI / 2) % (2 * Math.PI))}
          )rotate(${i * 6})`}
              >
                <path d={path} fill={color} />
              </g>
            );
          })}
          <g
            key={'bubble-minutes-2-' + minutes}
            transform={`matrix(
          1,
          0,
          0,
          1,
          ${250 - 250 * Math.cos((((2 * Math.PI) / 60) * minutes + Math.PI / 2) % (2 * Math.PI))},
          ${250 - 250 * Math.sin((((2 * Math.PI) / 60) * minutes + Math.PI / 2) % (2 * Math.PI))}
          )rotate(${minutes * 6})`}
          >
            <path
              d={minutes % 5 === 0 ? 'M-11,0 l11,0l11,0l-3,64l-8,0l-8,0Z' : 'M-11,0 l11,0l11,0l-3,48l-8,0l-8,0Z'}
              fill={config.major.minute}
            />
          </g>
          <g
            key={'seconds-' + seconds}
            transform={`matrix(
          1,
          0,
          0,
          1,
          ${250 - 250 * Math.cos((((2 * Math.PI) / 60) * seconds + Math.PI / 2) % (2 * Math.PI))},
          ${250 - 250 * Math.sin((((2 * Math.PI) / 60) * seconds + Math.PI / 2) % (2 * Math.PI))}
          )rotate(${seconds * 6})`}
          >
            <path
              d={seconds % 5 === 0 ? 'M-11,0 l11,0l11,0l-3,64l-8,0l-8,0Z' : 'M-11,0 l11,0l11,0l-3,48l-8,0l-8,0Z'}
              fill={config.major.second}
            />
          </g>
          {calendar && day && (
            <g
              key={'day-' + day}
              transform={`matrix(
          1,
          0,
          0,
          1,
          ${250 - 250 * Math.cos((((2 * Math.PI) / 60) * day + Math.PI / 2) % (2 * Math.PI))},
          ${250 - 250 * Math.sin((((2 * Math.PI) / 60) * day + Math.PI / 2) % (2 * Math.PI))}
          )rotate(${day * 6})`}
            >
              <path
                d={day % 5 === 0 ? 'M-11,0 l11,0l11,0l-3,64l-8,0l-8,0Z' : 'M-11,0 l11,0l11,0l-3,48l-8,0l-8,0Z'}
                fill={config.major.day}
              />
            </g>
          )}
          {[...Array(12)]
            .map((x, i) => i * 5)
            .map((x) => {
              return (
                <g
                  key={'hours' + x}
                  transform={`matrix(
                    1,
                    0,
                    0,
                    1,
                    ${250 - 250 * Math.cos((((2 * Math.PI) / 60) * x + Math.PI / 2) % (2 * Math.PI))},
                    ${250 - 250 * Math.sin((((2 * Math.PI) / 60) * x + Math.PI / 2) % (2 * Math.PI))}
                    )rotate(${x * 6})`}
                >
                  <circle r="14" fill={'#66ffff'} />
                </g>
              );
            })}
        </g>
      );
    case 'trapez':
    default:
      return (
        <g>
          {[...Array(60)].map((x, i) => {
            let path, color;
            if (i % 5 === 0) {
              path = 'M-11,0 l11,0l11,0l-3,64l-8,0l-8,0Z';
              color = config.major.highlight;
            } else {
              path = 'M-11,0 l11,0l11,0l-3,48l-8,0l-8,0Z';
              color = config.major.color;
            }

            if (celsius) {
              if (celsius > 0 && i <= celsius) {
                color = getColor(i);
              } else if (celsius < 0 && (i >= 60 + celsius || i == 0)) {
                color = getColor(i);
              } else if (celsius === 0) {
                color = getColor(i);
              }
            }
            if (calendar && i === day) {
              color = config.major.day;
            }

            if (i === seconds) {
              color = config.major.second;
            }

            if (i === minutes) {
              color = config.major.minute;
            }

            return (
              <g
                key={'trapez-major-' + i}
                transform={`matrix(
          1,
          0,
          0,
          1,
          ${250 - 250 * Math.cos((((2 * Math.PI) / 60) * i + Math.PI / 2) % (2 * Math.PI))},
          ${250 - 250 * Math.sin((((2 * Math.PI) / 60) * i + Math.PI / 2) % (2 * Math.PI))}
          )rotate(${i * 6})`}
              >
                <path d={path} fill={color} />
              </g>
            );
          })}
        </g>
      );
  }
};
